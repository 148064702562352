nav {
  width: 100%;
  height: 50px;
  max-width: 100%;
  display: flex;
  color: var(--dark);
  align-items: center;
  justify-content: flex-end;
  padding: 50px 80px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.logo {
  text-decoration: none;
  position: absolute;
  line-height: 15px;
  letter-spacing: 2px;
  width: fit-content;
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  flex-direction: column;
  left: 30px;
}
.nav-items{

  gap: 35px;
  list-style: none;
  background-color: var(--dark);
  border-radius: 15px;
  padding: 5px 2% 8px 2%;
  pointer-events: all;
  display: none;

}
.nav-items a{
  color: var(--light);
  text-decoration: none;
  position: relative;
  font-size: 1.2rem;
}
.nav-items a:hover::after{
  transform-origin: left;
  transform: scaleX(1);
}
.nav-items a::after{
  transition: transform 200ms var(--easing1);
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: right;
  background-color: var(--accent);
}
@media screen and (max-width: 890px) {
  .nav-items{
    display: none;
  }
}

.logo > a {
  color: var(--dark);
}
nav > div.icon-div {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  align-items: center;
  height: 100%;
  pointer-events: all;
  gap: 20px;
}
nav > div.icon-div > .nav-expand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: fixed;
  right: 25px;
  background-color: var(--dark);
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid var(--light);
}
nav > div.contact-div {
  display: flex;
  position: absolute;
  left: 20px;
  align-items: center;
  height: 100%;
}
nav .dot {
  /* position: absolute; */
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--light);
}

nav .expand {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0;
  z-index: 100;
  pointer-events: all;
}
nav .expand > div:nth-child(2) {
  display: grid;
  height: 100vh;
  grid-template-columns: 75px auto;
  background-color: var(--dark);
}
@media screen and (min-width: 600px) {
  nav .expand > div {
    width: 600px;
    min-width: 400px;
  }
  nav .expand {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

nav .nav-blur {
  width: 100%;
  height: 100vh;
  background: #000000bb;
  position: fixed;
  top: 0;
  left: 0%;
  backdrop-filter: blur(10px);
  z-index: 99;
}

nav .expand .body {
  display: grid;
  grid-template-rows: 130px auto 200px;
}
nav .expand .nav-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
}
nav .expand .nav-header span {
  position: absolute;
  bottom: 0;
  width: 0%;
  height: 1px;
  left: 0;
  background: var(--accent);
}

nav .expand .nav-header > div.container {
  overflow: hidden;
  position: relative;
}
nav .expand .nav-header > div.container h1 {
  font-weight: 400;
  font-family: "Marcellus", serif;
  color: var(--light);
}
nav .expand .nav-header > div.container p {
  margin-top: -7px;
  color: var(--light);
}

nav .expand .nav-footer {
  padding: 1rem 1.6rem;
  position: relative;
}
nav .expand .nav-footer > span {
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  right: 0;
  background: var(--accent);
}

nav .expand .nav-footer > div > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}
nav .expand .nav-footer > div > div > a {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  border-radius: 50%;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.23, 1, 0.32, 1);
}
nav .expand .nav-footer > div > div > a > svg {
  fill: var(--accent);
}
nav .expand .nav-footer > div > div > a:hover {
  background-color: var(--accent);
  transform: scale(1.2);
}
nav .expand .nav-footer > div > div > a:hover svg {
  fill: var(--dark);
}
nav .expand .close {
  border-radius: 50%;
  background-color: var(--accent);
  width: 30px !important;
  display: grid;
  place-items: center;
  aspect-ratio: 1;
  cursor: pointer;
}
nav .expand .nav-links {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
#contactGlobe {
  width: 80%;
  display: grid;
  place-items: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
  position: relative;
}
.earth {
  overflow: hidden;
  max-width: 90rem;
  width: 90%;
  aspect-ratio: 1;
  position: relative;
  border: 2px solid #507e1f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.earth > .bg {
  background-color: #7fe1ffb9;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -2;
}
.earth > .hor {
  width: 100%;
  border-radius: 50%;
  position: absolute;
  height: 50%;
  border: 1px solid #507e1f;
}
.earth > .ver {
  width: 50%;
  border-radius: 50%;
  position: absolute;
  height: 100%;
  border: 1px solid #507e1f;
}
.earth > .map {
  position: absolute;
  display: flex;
  animation: MapAnimation 150s linear infinite;
  z-index: -1;
}
#contactGlobe h1 {
  font-size: clamp(2rem, 4vw, 5rem);
  text-transform: uppercase;
  opacity: 1;
}
.address-container {
  position: absolute;
  z-index: 2;
  padding: 2.5rem;
}
#contactGlobe span {
  position: absolute;
  font-size: clamp(10rem, 10vw, 10rem);
  color: #4164ff;
  z-index: -1;
  top: 100px;
}
