header {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 50px 30px;
}
header > .header-logo {
  position: absolute;
  top: 50px;
  left: 30px;
}
#header-text {
  font-size: clamp(48px, 7vw, 90px);
  color: var(--accent-complementary);
  font-weight: lighter;
  text-align: center;
  margin-top: 80px;
}
#header-text > div {
  display: flex;
  line-height: clamp(48px, 10vw, 95px);
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* flex-wrap: wrap; */
}
.header-man.a {
  border-radius: 50%;
  width: 136px;
  height: 142px;
  position: absolute;
  top: -13px;
  left: -26px;  
}
.header-man {
  border-radius: 50%;
  width: 100px;
  aspect-ratio: 1;
}
#header-text .bold {
  font-weight: bold;
}
#header-text .italic {
  font-style: italic;
}
#header-text .accent-bg {
  background-color: var(--accent);
}

@media screen and (max-width: 900px) {
  #header-text {
    width: 98%;
  }
}
@media screen and (max-width: 750px) {
  .logo {
    top: 100px;
    right: 25px;
    left: auto;
  }
}
@media screen and (max-width: 450px) {
  #header-text {
    margin-top: 120px;
  }
  .header-man {
    width: 60px;
  }
}

.t1 {
  transform: translateY(-100px);
  opacity: 0;
}
.t2 {
  overflow: hidden;
  text-wrap: nowrap;
  position: relative;
}
.t2::after {
  content: "";
  position: absolute;
  width: var(--width);
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--accent-bg);
}
.t3 {
  position: relative;
}
.t3::before {
  content: "";
  position: absolute;
  width: var(--width);
  height: 70%;
  background-color: var(--accent);
  bottom: 0px;
  left: 0;
  z-index: -1;
}

.fa {
  font-size: clamp(20px, 2vw, 40px);
}
.fb {
  font-size: clamp(36px, 5vw, 64px);
}

.t4.fb {
  top: 110px;
}
.nav-logo-header {
  cursor: pointer;
  position: fixed;
  top: 90px;
  left: 10px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: #f5ffe08c;
  z-index: 50;
}
