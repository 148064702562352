@keyframes wipe-left {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
@keyframes wipe-left-close {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes wipe-left-2 {
  70% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
@keyframes wipe-left-2-close {
  70% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes wipe-top-nav-head {
  50% {
    bottom: 100%;
  }
  100% {
    bottom: 16px;
  }
}
@keyframes wipe-top-nav-head-2 {
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes line-expand {
  50% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes wipe-top-nav-links {
  50% {
    bottom: 100%;
  }
  100% {
    bottom: 0;
  }
}
@keyframes marquee {
  from {
    transform: translateX(-40%);
  }
  to {
    transform: translateX(40%);
  }
}
@keyframes MapAnimation {
  from {
    transform: translateX(25%);
  }
  to {
    transform: translateX(-25%);
  }
}
/* @keyframes marquee1 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
} */

@keyframes rotate{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}