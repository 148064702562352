#about {
  margin-top: 30px;
  background-color: var(--light);
  overflow-x: hidden;
}
#about > .container.pd {
  padding: clamp(1rem, 2vw, 3rem);
}
#about .p1 {
  margin-top: 50px;
  gap: 25%;
}
#about .p1 img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  max-width: 400px;
  max-height: 550px;
}
.p2 {
  margin-top: 80px;
  gap: 25%;
}
.p2 .font-1 {
  max-width: 550px;
}
.p2 .disc {
  max-width: 450px;
  width: 90%;
}
.p2 .disc .inner-circle {
  background-color: var(--light);
  width: 100px;
}
.p2 .disc img {
  width: 94%;
  height: 118%;
  animation-duration: 180s;
  filter: opacity(0.9) blur(0px) contrast(0.9);
}
.p3 {
  height: 70vh;
}

@media screen and (max-width: 885px){
    .p1{
        flex-direction: column-reverse;
    }
    .p2{
        flex-direction: column;
        gap: 50px;
    }
    .p2 .disc .inner-circle{
        width: 70px;
    }
}

.p3 a {
  text-decoration: none;
  position: relative;
  font-size: 1.2rem;
}
.p3 a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}
.p3 a::after {
  transition: transform 300ms var(--easing2);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  transform-origin: right;
  background-color: var(--accent-dark);
}
