* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  color: var(--dark);
  background-color: var(--accent-bg);
  overflow-x: hidden;
  font-family: "Chivo", sans-serif;
  font-weight: 300;
}
::selection {
  background-color: var(--accent);
  color: var(--accent-complementary-dark);
}
a{
  text-decoration: none;
}
.search-icon {
  fill: var(--nav-highlight);
  width: 23px;
  aspect-ratio: 1;
}

/* ###############TEXT MARQUEE############### */

.marquee {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  background: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.track > .content {
  font-size: 70px;
  display: flex;
  justify-content: space-between;
}
.track > .content span {
  color: var(--dark);
  /* background-image: var(--gradient-3); */
  /* background-clip: text; */
  margin-left: 80px;
  /* display: flex; */
  /* align-items: center; */
}
.track {
  rotate: 90deg;
  /* translate: -47%; */
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  color: white;
  animation: marquee 180s linear infinite;
}
/* ###############MARQUEE END############### */

/* #############COMING SOON########## */
#comingsoon {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#comingsoon h1 {
  font-size: clamp(2.5rem, 4rem, 7vw);
  letter-spacing: 2px;
}
#comingsoon span {
  opacity: 0.7;
}
/* ###############COMING SOON END############# */




.text-marquee {
  position: relative;
  width: 120%;
  overflow: hidden;
  height: 30px;
  background-color: var(--black);
  color: white;
}
.text-marquee.big {
  height: 4.5rem;
}
.track1 {
  --time: 120s;
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee1 var(--time) linear infinite;
  font-size: 1.3rem;
}
.track1.big {
  font-size: 3rem;
}

#homeGrid {
  background-color: var(--light);
  min-height: 90vh;
}
.approach .circle span{
  background-color: var(--accent-dark);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.approach.flex{
  flex-wrap: wrap;
 gap: 20px;
}

.contact{
  background-color: var(--dark);
  border-radius: 20px;
  color: var(--light);
  border-radius: 40px;
  width: 90%;
  min-height: 400px;
  padding: clamp(1rem, 2vw, 3rem);
}
.contact > .container{
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 300px;
  border-top: 1px solid var(--light);
  padding: 3px 0;
  gap: 3px;
}
@media screen and (max-width: 770px) {
  .contact > .container{
    grid-template-columns: 100%;
  }
}
.input{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b1b16;
  flex-direction: column;
  padding-top: 2.5rem ;
}
.input input, .input textarea{
  width: 100%;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--light);
  font-size: clamp(2rem, 2.3vw, 5rem);
  padding: 1rem;
}
.input textarea{
  max-height: 200px;
  min-height: 200px;
  max-width: calc(100% + 10px);
  min-width: calc(100% + 10px);
}
.input > label{
  position: absolute;
  font-size: clamp(1.4rem, 2.5vw, 3rem);
  top: 10px;
  left: 10px;
  transition: opacity 200ms ease;
}
.input input:focus + label,
.input textarea:focus + label{
  opacity: 0.6;
}
