

/* ############HEADINGS############# */
.heading {
  color: var(--text-default);
 
}
.heading.h1 {
  font-size: clamp(2rem, 3.5vw, 5rem);
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  /* font-style: italic; */
}
.heading.h2 {
  font-size: clamp(2rem, 3vw, 3rem);
  
}
.heading.h3 {
  font-size: clamp(1.7rem, 2vw, 3rem);
}
.heading.xl {
  font-size: clamp(3rem, 5vw, 5rem);
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  /* font-style: italic; */
}
.heading.ls-1 {
  letter-spacing: 2px;
}
.heading.ls-2 {
  letter-spacing: 3px;
}
.heading.ls-3 {
  letter-spacing: 5px;
}
.heading.lsn-1 {
  letter-spacing: -2px;
}
.heading.lsn-2 {
  letter-spacing: -3px;
}
.heading.fw-light {
  font-weight: 300;
}
.heading.fw-4 {
  font-weight: 400;
}
.heading.fw-6 {
  font-weight: 600;
}
.heading.uppercase{
  text-transform: uppercase;
}

/* ##########FONTS############## */
.font-1{
  font-family: 'Chivo', sans-serif !important;
  font-weight: lighter;
  font-size: clamp(20px, 1.5vw, 24px);
}
.font-2{
  font-family: 'Playfair Display', serif;
}
.font-1.-m, .font-2.-m{
  font-size: clamp(24px, 2vw, 40px);
}
.font-1.-l, .font-2.-m{
  font-size: clamp(30px, 2vw, 40px);
}


/* ##########SPACINGS############# */
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 50px;
}
.mx-1 {
  margin-right: 10px;
  margin-left: 10px;
}
.mx-2 {
  margin-right: 20px;
  margin-left: 20px;
}
.mx-3{
  margin-right: 30px;
  margin-left: 30px;
}
.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mnt-1 {
  margin-top: -10px;
}
.mnt-2 {
  margin-top: -20px;
}
.pd-1 {
  padding: 10px;
}
.pd-2 {
  padding: 15px;
}
.pd-3 {
  padding: 20px;
}
.pd-m{
  padding: clamp(1rem, 3vw, 3rem);
}

/* #########COLORS######### */
.accent {
  color: var(--accent) !important;
}
.accent-dark {
  color: var(--accent-dark) !important;
}
.accent-complementary {
  color: var(--accent-complementary);
}
.text-highlight {
  color: var(--text-highlight);
}
.text-light {
  color: var(--light);
}
.text-gradient {
  background-image: linear-gradient(to right, #a6c461, #c0b861, #ac4b6b);
  background-clip: text;
  color: transparent;
}

/* ########CONTAINERS########## */
.container {
  width: 90%;
}
.container.bg-light{
  background-color: var(--light);
}
.container.fluid {
  width: 100%;
}
.container.fluid-h{
  height: 100%;
}
.container.mh-4 {
  min-height: 400px;
}
.container.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container.flex.row{
  flex-direction: row;
}
.container.center.left {
  align-items: flex-start;
}
.container.hidden {
  overflow: hidden;
}
.container.bg-highlight {
  background: var(--accent-bg);
}
.container.bg-highlight-dark {
  background: var(--accent-bg-dark);
}
.container.round {
  border-radius: 20px;
  padding: 1rem;
}
.container.chip{
  padding: 3px 20px;
  color: var(--accent);
  width: fit-content;
  border-radius: 30px;
  font-size: 1.2rem;
  background-color: var(--accent-bg-dark);
}
.container.chip.light{
  background-color: var(--accent-bg);
}
.shadow{
  box-shadow: 0px 0px 20px #0000003d;
}
.relative{
  position: relative;
}

.line{
  width: 100%;
  height: 1px;
  background-color: var(--dark);
}

/* #########DISPLAY############ */
.flex {
  display: flex;
}
.flex.column {
  flex-direction: column;
}
.flex.space-between {
  justify-content: space-between;
}
.flex.right {
  justify-content: right;
}
.flex.column.right {
  align-items: flex-end;
}
.flex.left {
  justify-content: left;
}
.flex.center {
  align-items: center;
}
.flex.justify-center {
  justify-content: center;
}
.flex.gap{
  gap: 10px;
}