.btn {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.btn-link{
  font-size: clamp(2.5rem, 3vw, 4rem);
}
.btn-link i{
  color: var(--accent-dark);
  transform: rotate(45deg);
  font-size: 6rem;
  transition: transform 250ms ease;
}
.btn-link p {
  position: relative;
}

.btn-link P::after {
  content: "";
  border-bottom: solid 3px var(--accent-dark);
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.25s var(--easing1);
}

.btn-link:hover P::after {
  transform-origin: left;
  transform: scaleX(1);
}
.btn-link:hover i{
  transform: translateY(-100%) rotate(0);
}
.btn-link i[data-b]{
  transform: translateY(100%) rotate(45deg);
  position: absolute;
  left: 0;
}
.btn-link:hover i[data-b]{
  transform: translateY(0) rotate(45deg);
}

.btn-link-icon-complementary{
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--accent-complementary);
}
.btn-link-icon-complementary i{
  transform: rotate(45deg);
  transition: transform 250ms var(--easing1);
  position: absolute;
  transform: rotate(45deg);
}
.btn-link-icon-complementary:hover i.a{
  transform: rotate(45deg) translate(50px, 0px);
  
}
.btn-link-icon-complementary i.b{
  transform: translate(-50px, -50px) rotate(45deg);
}
.btn-link-icon-complementary:hover i.b{
  transform: translate(0,0) rotate(45deg);
}
.btn.btn-primary{
  background-color: var(--accent-complementary);
  color: var(--light);
  padding: 10px 20px;
  border: none;
  font-size: 1.3rem;
  transition: background-color 100ms ease;
}
.btn.btn-primary:hover{
  background-color: var(--accent-complementary-dark);
}