.services {
  background-color: var(--dark);
  border-radius: 40px;
  width: 90%;
  min-height: 400px;
  transform: translateY(-130px);
  padding: clamp(1rem, 2vw, 3rem);
}
.services > div.container {
  display: grid;
  grid-template-columns: 50% 50%;
  
  border-top: 1px solid var(--light);
}
.services > div > div {
  height: 100%;
  min-height: 350px;
  max-height: 600px;
  padding: 2rem;
}
.services > div > div.pd-3 {
  padding: 3rem;
}
.services .border.bottom {
  border-bottom: 1px solid #f6f6f67e;
}
.services .border.r {
  border-right: 1px solid #f6f6f67e;
}
.services .border.bottom.responsive{
  border-bottom: none;
}
.services .pointer {
  cursor: pointer;
}
.services img {
  width: 90%;
  border-radius: 25px;
}

.services h1.accent {
  font-size: cl;
}
.services a {
  color: var(--accent-complementary);
  text-decoration: none;
  transition: color 100ms;
}
.services a:hover {
  color: var(--light);
}
.img-hover {
  width: 250px;
  border-radius: 20px;
  height: 10px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3;
  background-color: #000000b7;
  backdrop-filter: blur(20px);
  overflow: hidden;
  opacity: 0;
  transition-property: opacity, height;
  transition-duration: 200ms;
  transition-timing-function: var(--easing1);
  color: #4f6fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.8rem;
}
.img-hover .line{
  width: 90%;
  height: 1px;
  background-color: #4f6fff;
}
.img-hover.active {
  height: 100px;
  opacity: 1;
}
@media screen and (max-width: 935px) {
  .services img {
    width: 100%;
  }
  .services > div.container{
    grid-template-columns: 100%;
    place-items: center;
  }
  .services .border.r {
    border-right: none;
  }
  .services > div > div{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .services > div > div.pd-3 {
    padding: 1rem;
  }
  .services-img-container{
    width: 100%;
    justify-content: center !important;
    align-items: center;
  }
  .services .border.bottom.responsive{
    border-bottom: 1px solid #f6f6f67e;
  }
}

.service-contact-followup{
  margin-top: -50px;
  background-color: var(--dark);
  width: 90%;
  max-width: 1500px;
  border-radius: 40px;
  color: var(--light);
  padding: clamp(0.8rem, 1.5vw, 1.5rem) clamp(1rem, 5vw, 4rem);
}
.service-contact-followup a{
  padding: 10px 50px;
  background-color: var(--accent-complementary);
  color: var(--light);
  text-decoration: none;
  border-radius: 10px;
  margin-top: 20px;
  transition: background-color 100ms ease;
}
.service-contact-followup a:hover{
  background-color: var(--accent-complementary-dark);
 
}
