.home-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: clamp(15px, 5vw, 60px);
}
.home-grid > div {
  width: 100%;
  min-height: 300px;
  border-bottom: 1px solid var(--dark);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(0.8rem, 3vw, 4rem);
}

.home-grid > div:nth-child(odd){
  border-right: 1px solid var(--dark);
}

.testimonial{
  display: grid;
  grid-template-rows: 50px auto 50px;
  width: 100%;
  height: 100%;
  place-items: center;
}
.testimonial .num, .testimonial span{
  font-size: 4.7rem;
  font-weight: 400;
}
.grid-last p{
  font-size: clamp(2rem, 3vw, 5rem);
  font-weight: lighter;
  letter-spacing: 3px;
}

@media screen and (max-width:900px){
  .home-grid{
    grid-template-columns: 90%;
    place-content: center;
  }
  .home-grid > div:nth-child(odd){
    border-right: none;
  }
  .home-grid > div {
    border-bottom: 1px solid var(--dark);
  }
}