#disc{
    padding: clamp(1rem, 2vw, 3rem);
}
#disc .font-1{
    /* max-width: 500px; */
    width: 50%;
    min-width: 300px;
    height: 100%;
}
#disc > div{
    width: 90%;
}
.disc{
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    width: 350px;
    aspect-ratio: 1;
}
.disc .inner-circle{
    position: absolute;
    width: 80px;
    aspect-ratio: 1;
    background-color: var(--accent-bg);
    border-radius: 50%;
}
.disc img{
    filter: opacity(0.9) blur(5px) contrast(0.9);
}
@media screen and (max-width: 700px) {
    #disc > div > div.flex:nth-child(odd){
        flex-direction: column-reverse;
        gap: 20px;
    }
    #disc > div > div.flex:nth-child(even){
        flex-direction: column;
        gap: 20px;
    }
    .disc{
        width: 280px;
    }
}