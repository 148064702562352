.trailsText {
  position: relative;
  width: 100%;
  height: 60px;
  will-change: transform, opacity;
  overflow: hidden;
  cursor: pointer;
}
.trailsText span {
  color: var(--accent);
}
.trailsText a {
  line-height: 50px;
  color: var(--light);
  font-size: 2.3rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-decoration: none;
  margin-left: 30px;
  position: relative;
  transition: all 300ms cubic-bezier(0.85, 0.16, 0.16, 0.85);
  display: flex;
  flex-direction: column;
  transform: translateY(-25px);
}
nav .trailsText:hover a {
  transform: translateY(-4rem);
}
nav .trailsText a::after {
  content: attr(data-link);
  width: 100%;
  height: 100%;
  font-size: 2rem;
  color: var(--accent);
  transform: translateY(4rem);
  transition: all 300ms cubic-bezier(0.85, 0.16, 0.16, 0.85);
}
nav .trailsText:hover a::after {
  transform: translateY(0);
}
.trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}
